.popup-catalog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(71, 68, 68, 0.768);
  bottom: 0;
  z-index: 999999;
  > .container {
    position: fixed;
    z-index: 10000;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2rem;
    > svg {
      position: absolute;
      cursor: pointer;
      z-index: 100000;
      &:nth-child(1) {
        right: 4rem;
        top: 2rem;
      }
      &:nth-child(2) {
        right: 6rem;
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        left: 6rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    > .scroll {
      display: flex;
      height: 90vh;
      width: 90vw;
      overflow-y: auto;
      padding: 10rem 15rem 20rem;
      > .content {
        width: 45%;
        position: relative;
        > .title {
          font-weight: bold;
          font-size: 4rem;
          padding-right: 45px;
        }
        > .inventaris {
          font-size: 1.4rem;
          margin-top: 1rem;
          > span {
            color: #252b42;
            display: flex;
            font-weight: 300;
          }
        }
        .number {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 50%;
          background-color: #0081ab;
          color: #fff;
          width: 5rem;
          height: 5rem;
          display: grid;
          place-items: center;
        }
        > .separator {
          height: 1px;
          background-color: #0081ab;
          width: 93%;
          margin: 2.5rem 0;
        }
        > .sub-title {
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 2rem;
        }
        > .text {
          font-size: 1.4rem;
          width: 93%;
          text-align: justify;
          &.pb {
            padding-bottom: 7rem;
          }
        }
      }
      > .image {
        width: 55%;
        > .img-container {
          display: flex;
          justify-content: center;
          background-color: #b22222;
          box-shadow: 0 6px 4px -2px rgba(0, 0, 0, 0.4);
          img {
            width: 30rem;
          }
        }
        figcaption {
          background-color: #ff7b47;
          color: #fff;
          border-radius: 2rem;
          width: 80%;
          margin: 3rem auto 0;
          text-align: center;
          height: 6.1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 1.4rem;
          font-weight: 300;
        }
      }
    }
  }
  figcaption {
    background-color: #ff7b47;
    color: #fff;
    border-radius: 2rem;
    width: 80%;
    margin: 3rem auto 0;
    text-align: center;
    height: 6.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 300;
  }
  &.mobile {
    background: rgba(71, 68, 68, 0.768);
    > .container {
      background-color: #d9d9d9;
      > svg {
        right: 2rem;
        top: 2rem;
        height: 3rem;
        width: 3rem;
      }
      > .scroll {
        padding: 3rem;
        > .content {
          width: 100%;
          > .title {
            font-size: 2.5rem;
            text-align: center;
            color: #0081ab;
          }
          > .sub-title {
            margin-bottom: 1rem;
            font-size: 1.5rem;
          }
          > .inventaris {
            span {
              text-align: center;
              justify-content: center;
              width: 100%;
              margin: 0 auto;
            }
          }
          > .image {
            position: relative;
            width: 22rem;
            height: 13rem;
            margin: 1rem auto 0;
            background-color: #b22222;
            border-radius: 1rem;
            svg {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              height: 3rem;
              width: 3rem;
              cursor: pointer;
              &:nth-child(1) {
                right: -5rem;
              }
              &:nth-child(2) {
                left: -5rem;
              }
            }
            .number {
              top: 1rem;
              right: 1rem;
              width: 3rem;
              height: 3rem;
              font-size: 1.4rem;
            }
            .img-container {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                height: 13rem;
              }
            }
          }
        }
      }
    }
    figcaption {
      font-size: 1rem;
    }
  }
}
