.carousel-root {
  .container {
    padding: 0 15rem;
    img {
      height: 50rem;
      border-radius: 3.2rem;
      width: 89.4rem;
    }
  }
  .slider {
    width: 85%;
  }
  .carousel.carousel-slider .control-arrow {
    width: 7rem;
    height: 7rem;
    top: 50%;
    margin: 0 2rem;
    border-radius: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.4);
    &:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }
}

.image-slider {
  display: flex;
  column-gap: 2rem;
  overflow-x: auto;
  padding: 0 3rem;
  margin-top: -5rem;
  &::-webkit-scrollbar {
    display: none;
  }
  .container {
    img {
      width: 25rem;
      height: 16rem;
      border-radius: 1rem;
    }
  }
}
@media (min-width: 600px) and (max-width: 700px) {
  .carousel-root {
    .container {
      padding: 0 8rem;
      img {
        height: 30rem;
        width: 49rem;
      }
    }
  }
}
@media (min-width: 700px) and (max-width: 950px) {
  .carousel-root {
    .container {
      padding: 0 10rem;
      img {
        height: 35rem;
        width: 58rem;
      }
    }
  }
}
@media (min-width: 950px) and (max-width: 1100px) {
  .carousel-root {
    .container {
      padding: 0 15rem;
      img {
        height: 40rem;
        width: 70rem;
      }
    }
  }
}
@media (min-width: 1100px) and (max-width: 1440px) {
  .carousel-root {
    .container {
      padding: 0 10rem;
      img {
        height: 45rem;
        width: 81rem;
      }
    }
  }
}
