@import '../../sass//mixins';

.catalog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  .cards {
    margin-top: 5rem;
    display: flex;
    column-gap: 7rem;
  }
  .see-more {
    margin-top: 5rem;
  }
  @include respond(phone) {
    margin-top: 5rem;
    justify-content: flex-start;
    .section {
      .title {
        text-align: center;
      }
      .sub-title {
        display: none;
      }
    }
    .cards {
      display: flex;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 3rem;
      margin-top: 0;
      column-gap: 2rem;
      &::-webkit-scrollbar {
        display: none;
      }
      .card {
        display: inline-flex;
      }
    }
    .see-more {
      margin-top: 1rem;
      svg {
        height: 1.5rem;
      }
    }
  }
  @media (max-width: 409px) {
    margin-top: 8rem;
  }
  @media (max-width: 371px) {
    margin-top: 10rem;
  }
  @media (max-width: 355px) {
    margin-top: 12rem;
  }
  @media (max-width: 331px) {
    margin-top: 14rem;
  }
}
