@import '../../sass//mixins';

.card-touch {
  width: 32.8rem;
  height: 33.3rem;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  transition: all 0.5s;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 0.5rem;
  svg {
    margin-bottom: 2rem;
    path {
      fill: #0081ab;
    }
  }
  h5 {
    font-size: 1.6rem;
    font-weight: 300;
  }
  span {
    margin: 1.5rem 0;
    font-weight: 600;
    text-align: center;
  }
  a {
    width: 11.7rem;
    height: 4.4rem;
    color: #ff7b47;
    border: 1px solid #ff7b47;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: transparent;
    display: grid;
    place-items: center;
  }
  &:hover {
    height: 39.3rem;
    background-color: #0081ab;
    color: #fff;
    a {
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
  &:nth-child(2) {
    svg {
      margin-left: 20px;
    }
  }
  @include respond(phone) {
    width: calc(0.8 * 32.8rem);
    height: calc(0.8 * 40.3rem);
    svg {
      width: 8rem;
      height: 8rem;
    }
  }
}
