.see-more {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #0081ab;
  text-decoration: none;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
}
