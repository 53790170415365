.games {
  display: flex;
  padding: 7rem 0;
  position: relative;
  > svg {
    position: absolute;
    top: 4rem;
    right: 5rem;
    cursor: pointer;
  }
  > .images {
    min-width: 60%;
    display: flex;
    justify-content: center;
    > img {
      width: 47.7rem;
      height: 88.2rem;
    }
  }
  > .content {
    line-height: 1.1;
    margin-top: 7rem;
    padding-right: 5rem;
    > .title {
      font-weight: bold;
      font-size: 4rem;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -1rem;
        left: 0;
        height: 1px;
        background-color: #0081ab;
        width: 80%;
      }
    }
    > .middle {
      > h5 {
        margin: 7rem 0 1rem;
        font-size: 2rem;
        font-weight: 300;
      }
      > p {
        font-size: 2rem;
      }
      > h2 {
        font-size: 4rem;
        letter-spacing: -3px;
        margin-top: 0.5rem;
      }
      > span {
        font-size: 2rem;
        display: flex;
        margin: 1rem 0;
      }
      > img {
        height: 14rem;
        width: 14rem;
      }
      > figcaption {
        background-color: #0081ab;
        color: #fff;
        width: 14rem;
        height: 4.3rem;
        border-radius: 0.5rem;
        display: grid;
        place-items: center;
      }
    }
    > .bottom {
      margin-top: 8rem;
      display: flex;
      column-gap: 5rem;
      > img {
        height: 6rem;
      }
    }
  }
}
