@import '../../sass//mixins';

.history {
  padding: 0 15rem;
  position: relative;
  @include respond(phone) {
    padding: 0;
    display: flex;
    justify-content: space-between;
    .section {
      .sub-title {
        display: none;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1200px) {
    padding: 0 5rem;
  }
}
