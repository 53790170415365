@import '../../sass/mixins';

.get-in-touch {
  padding-bottom: 15rem;
  .cards {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3rem;
  }
  @include respond(phone) {
    padding: 5rem 0 5rem;
    display: flex;
    flex-direction: column;
    .section {
      min-width: 100%;
      .title {
        text-align: center;
      }
      .sub-title {
        display: none;
      }
    }
    .cards {
      flex-direction: column;
      row-gap: 5rem;
      margin-top: 2rem;
    }
  }
}
