@import '../../sass//mixins';

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .donation {
    height: 14.2rem;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15rem;
    .title {
      font-weight: bold;
      font-size: 2.4rem;
      span {
        color: #0081AB;
      }
    }
    .desc {
      margin-top: 1rem;
      width: 37rem;
      font-size: 1.4rem;
      line-height: 1.5;
    }
    a {
      width: 19.2rem;
      height: 5.9rem;
      border-radius: 0.5rem;
      color: #fff;
      background-color: #ff7b47;
      border: none;
      cursor: pointer;
      display: grid;
      place-items: center;
    }
  }
  & &__cendramata {
    .desc {
      width: 60rem;
      word-spacing: 0.2px;
      font-size: 14px;
    }
    .link-container {
      display: flex;
      gap: 2rem;
      a {
        width: 10rem;
        font-size: 12px;
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
  &-bottom {
    background-color: #045d7a;
    height: 7.4rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15rem;
    font-size: 1.4rem;
    .social-media {
      display: flex;
      column-gap: 1rem;
      svg {
        font-size: 2.4rem;
        cursor: pointer;
      }
    }
  }
  @include respond(tab-land) {
    .donation {
      padding: 2rem 3rem;
      height: auto;
      flex-direction: column;
      .title,
      .desc {
        font-size: 1.5rem;
      }
      a {
        width: 12rem;
        height: 3.5rem;
        font-size: 1.4rem;
      }
    }
    & &__cendramata {
      .desc {
        width: 100%;
        word-spacing: 0.2px;
        font-size: 14px;
      }
      .link-container {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        margin-top: 10px;
        a {
          width: 4rem;
          height: 4rem;
          font-size: 12px;
          svg {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
    &-bottom {
      padding: 0 3rem;
    }
  }
}
