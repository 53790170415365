.game-mobile {
  > .home {
    display: grid;
    place-items: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    img {
      width: 12.1rem;
      height: 7.8rem;
      &:nth-child(2) {
        position: absolute;
        width: 100vw;
        height: 182px;
        bottom: 0;
        object-position: top;
        object-fit: cover;
      }
    }
  }
  > .info {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 700px;
    position: relative;
    > .top {
      height: 7rem;
      width: 100%;
      background-color: #0081ab;
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4rem 4rem;
      gap: 6rem;
      > .title {
        font-weight: 400;
        font-size: 4.8rem;
        font-family: 'Mouse Memoirs', sans-serif;
        text-align: center;
        letter-spacing: 0.2px;
        line-height: 0.9;
        width: 100%;
        margin: 0 auto;
      }
      > p {
        text-align: center;
        font-size: 2rem;
        line-height: 1.5;
      }
      > svg {
        position: absolute;
        bottom: 5rem;
        &:active {
          opacity: 0.8;
        }
      }
    }
  }
  > .let-play {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0081ab;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    > .title {
      font-weight: 400;
      font-size: 4.8rem;
      font-family: 'Mouse Memoirs', sans-serif;
      color: #fff;
      margin: 15rem 0;
    }
    > button {
      background-color: #fff;
      border: none;
      height: 5.8rem;
      border-radius: 0.5rem;
      width: 18.8rem;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      &:active {
        opacity: 0.8;
      }
      &:hover {
        background-color: #ff7b47;
        color: #fff;
      }
      &:nth-child(3) {
        width: 22.4rem;
        margin-top: 3rem;
      }
    }
  }
  > .cara-bermain {
    position: relative;
    > .content {
      > img {
        width: 250px;
      }
      > svg {
        position: static;
      }
      > button {
        width: 18.8rem;
        height: 5.8rem;
        border-radius: 0.5rem;
        border: none;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        font-weight: 600;
        &:active {
          opacity: 0.8;
        }
      }
    }
    > .footer {
      margin-top: 5rem;
      > .logos {
        display: flex;
        justify-content: center;
        gap: 3rem;
        margin-bottom: 3rem;
        > img {
          height: 30px;
        }
      }
      > .bottom {
        background-color: #0081ab;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 87px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 1.3;
        font-weight: 300;
      }
    }
  }
  > .mulai {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 650px;
    position: relative;
    > .top {
      height: 7rem;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 1px 2px black;
      display: grid;
      place-items: center;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -1px;
      }
    }
    > .content {
      background-color: #e5e5e5;
      height: calc(100vh - 7rem);
      padding: 5rem 3rem;
      > p {
        text-align: center;
        font-size: 1.3rem;
      }
      .boxes {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        margin-top: 6rem;
        > .row-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1.5rem;
          > .box {
            height: 13.2rem;
            width: 9.6rem;
            position: relative;
            > .image {
              width: 100%;
              height: 100%;
              background-color: #0081ab;
              border-radius: 0.7rem;
              > img {
                width: 7.2rem;
                position: absolute;
                bottom: 1rem;
                left: 50%;
                transform: translateX(-50%);
              }
              &.show {
                background-color: #b22222;
              }
            }
            > .text {
              width: 100%;
              height: 100%;
              background-color: #fff;
              display: grid;
              place-items: center;
              font-weight: bold;
              border-radius: 0.7rem;
              text-align: center;
            }
          }
        }
      }
      > .bottom {
        display: flex;
        gap: 10rem;
        position: absolute;
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);
        svg:active {
          opacity: 0.8;
        }
      }
    }
  }
  .keris {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 750px;
    position: relative;
    background-color: #e5e5e5;
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .title {
        font-weight: 400;
        font-size: 4.8rem;
        font-family: 'Mouse Memoirs', sans-serif;
        margin-bottom: 3rem;
      }
      > .image {
        background-color: #b22222;
        border-radius: 10px;
        img {
          width: 32.8rem;
          height: 45.1rem;
          border-radius: 1rem;
          margin-top: 3rem;
        }
      }
      > svg {
        position: absolute;
        bottom: 5rem;
        &:active {
          opacity: 0.8;
        }
      }
      > .scan {
        margin-top: 3rem;
        p {
          text-align: center;
          font-size: 1.5rem;
        }
      }
    }
  }
}
