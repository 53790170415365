@import '../../sass/mixins';

.catalog-page {
  display: flex;
  flex-direction: column;
  > .grid {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    > .card {
      width: calc(48.5rem * 0.85);
      height: calc(32.5rem * 0.85);
      > .image img {
        width: calc(24.2rem * 0.85);
      }
      > .desc {
        font-size: 1.4rem;
        > .number {
          font-size: 1.2rem;
        }
      }
    }
  }
  @include respond(phone) {
    padding-top: 10rem;
    > .section {
      min-width: 100%;
      > .title,
      .sub-title {
        text-align: center;
      }
    }
    > .grid {
      > .card {
        width: calc(48.5rem * 0.85);
        height: calc(32.5rem * 0.85);
        display: flex;
        justify-content: center;
        > .image img {
          width: calc(24.2rem * 0.5);
        }
        > .desc {
          font-size: 1rem;
          > .number {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
