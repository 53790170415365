@import '../../sass//mixins';

.section {
  margin-top: 15rem;
  > .title {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  > .sub-title {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
  }
  @include respond(phone) {
    min-width: 55%;
    max-width: 55%;
    text-align: start;
    padding: 0 4rem;
    margin-top: 0;
    > .title {
      font-size: 2rem;
      text-align: start;
    }
    > .sub-title {
      text-align: justify;
    }
  }
}
