@import '../../sass/mixins';

.card {
  display: flex;
  width: 48.5rem;
  height: 32.5rem;
  cursor: pointer;
  .desc {
    width: 50%;
    background-color: #1d1d1d;
    color: #fff;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    position: relative;
    justify-content: space-between;
    .title {
      font-weight: 600;
    }
    > .number {
      position: absolute;
      right: 1.5rem;
      height: 5rem;
      width: 5rem;
      background-color: #ff7b47;
      border-radius: 50%;
      place-items: center;
      display: grid;
      font-size: 1.4rem;
    }
    .inventaris {
      position: absolute;
      bottom: 1.5rem;
    }
  }
  .image {
    background-color: #b22222;
    img {
      width: 24.2rem;
      height: 100%;
      object-fit: cover;
    }
  }
  &:hover {
    & .desc {
      .title,
      .inventaris {
        color: #0081ab;
        transition: all 0.2s;
      }
      > .number {
        background-color: #0081ab;
        transition: all 0.2s;
      }
    }
  }
  @include respond(tab-land) {
    min-width: calc(48.5rem * 0.9);
    max-width: calc(49.5rem * 0.9);
    min-height: calc(32.5rem * 0.9);
    max-height: calc(32.5rem * 0.9);
    > .image {
      border-radius: 0 10px 10px 0;
      img {
        width: calc(24.2rem * 0.9);
        border-radius: 0 10px 10px 0;
      }
    }
    > .desc {
      font-size: 1.6rem;
      border-radius: 10px 0 0 10px;
      > .number {
        right: 0;
        top: 1rem;
        height: 4rem;
        width: 4rem;
        font-size: 1.5rem;
      }
    }
  }
  @include respond(tab-port) {
    min-width: calc(48.5rem * 0.6);
    max-width: calc(48.5rem * 0.6);
    min-height: calc(32.5rem * 0.6);
    max-height: calc(32.5rem * 0.6);
    > .image {
      border-radius: 0 10px 10px 0;
      img {
        width: calc(24.2rem * 0.6);
        border-radius: 0 10px 10px 0;
      }
    }
    > .desc {
      font-size: 1rem;
      border-radius: 10px 0 0 10px;
      > .number {
        right: -3rem;
        top: 1rem;
        height: 3rem;
        width: 3rem;
        font-size: 1.2rem;
      }
    }
  }
}
