@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,900;1,700;1,900&display=swap');
@import 'mixins';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  --color-primary: #0081ab;
}

html {
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px; 9px/16px = 56.25%
  }

  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px; 8px/16px = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px; 12px/16px = 75%
  }
}

a {
  text-decoration: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}
