@import '../../sass//mixins';

.popup-home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.768);
  bottom: 0;
  z-index: 999999;
  .container {
    position: fixed;
    z-index: 10000;
    background-color: #d9d9d9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    > svg {
      position: absolute;
      top: 3rem;
      right: 3rem;
      cursor: pointer;
    }
    .scroll {
      height: 90vh;
      padding: 5rem 20rem;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      > .title {
        font-size: 4rem;
        font-weight: 600;
        text-align: center;
      }
      > svg {
        cursor: pointer;
        position: absolute;
        right: 3rem;
        top: 3.5rem;
      }
      > img {
        margin-top: 4rem;
        width: 89.9rem;
        max-width: 80vw;
        height: 55rem;
        border-radius: 3.2rem;
      }
      > .content {
        text-align: justify;
        > .desc,
        > .misi,
        > .visi {
          margin-top: 3rem;
          ul li {
            cursor: default;
            font-weight: 400;
          }
        }
        h5 {
          font-size: 1.6rem;
        }
      }
    }
  }
  @include respond(phone) {
    .container {
      width: 90vw;
      > svg {
        width: 3rem;
        height: 3rem;
      }
      .scroll {
        padding: 5rem;
        .title {
          font-size: 3rem;
        }
        > img {
          width: calc(0.3 * 89.9rem);
          height: calc(0.3 * 55rem);
          border-radius: calc(0.3 * 3.2rem);
          margin: 0 a;
        }
      }
    }
  }
}
