.category-card {
  height: 363px;
  width: 247px;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    object-fit: cover;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .detail {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 168px;
    width: 100%;
    background-color: rgb(217, 217, 217, 60%);
    padding: 3rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    .title {
      font-weight: 600;
    }
    .sub-title {
      font-weight: 300;
      margin-bottom: 1rem;
    }
    button {
      background-color: #FF7B47;
      border: none;
      color: #fff;
      border-radius: 10px;
      padding: 1.5rem 0;
      cursor: pointer;
    }
  }
}


.item-card {
  cursor: pointer;
  img {
    height: 247px;
    width: 247px;
    border-radius: 25px;
    object-fit: cover;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .detail {
    margin-top: 1rem;
    text-align: center;
    font-size: 14px;
    .title {
      font-weight: 600;
    }
    .price {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}