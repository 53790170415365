.move-to-top {
  z-index: 100000;
  position: fixed;
  background-color: #f9f9f9;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  bottom: 5rem;
  right: 5rem;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    background-color: rgb(0, 129, 171);
    svg path {
      fill: #fff;
    }
  }
  svg {
    font-size: 3rem;
    path {
      fill: rgb(0, 129, 171);
    }
  }
}
