@import '../../sass/mixins';

.pages_history {
  display: flex;
  flex-direction: column;
  > .container {
    padding: 0 15rem 10rem 15rem;
  }
  @include respond(phone) {
    .section {
      padding: 10rem 0 5rem;
      min-width: 100%;
      > .title,
      .sub-title {
        text-align: center;
      }
    }
    > .container {
      padding: 0;
      display: flex;
      flex-direction: column;
      row-gap: 3rem;
      .content-history {
        width: 100%;
        padding: 0;
        align-items: center;
        height: 100%;
        row-gap: 3rem;
        > .image {
          width: 100%;
          img {
            margin: 0 auto;
            border-radius: 10px;
            width: 22.2rem;
            height: 14.6rem;
          }
          figcaption {
            margin-top: 0.7rem;
            color: #0081ab;
            font-size: 1rem;
          }
        }
        .content {
          padding: 0 3rem;
          > .title {
            text-align: start;
          }
          > .desc {
            position: static;
            min-width: 100%;
          }
        }
        &.reverse {
          flex-direction: column;
        }
      }
      &:last-child {
        margin-bottom: 3rem;
      }
    }
    .keris-history {
      flex-direction: column;
      row-gap: 3rem;
      margin: 3rem 0;
      padding: 3rem;
      .container {
        width: 100%;
        img {
          width: 15rem;
          height: 22.3rem;
        }
        .desc {
          margin-top: 3rem;
          width: 100%;
        }
      }
    }
  }
}
