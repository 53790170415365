@import '../../sass//mixins';

.content-history {
  margin-top: 8rem;
  display: flex;
  font-weight: 600;
  position: relative;
  > .image {
    width: 60rem;
    > img {
      width: 60rem;
      height: 44.5rem;
      margin-bottom: 2rem;
    }
    > figcaption {
      text-align: center;
      margin: 0 auto;
      font-size: 1.4rem;
    }
  }
  > .content {
    padding: 2rem 0 0 10rem;
    > .title {
      color: #0081ab;
      font-size: 4rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    > .desc {
      font-size: 1.4rem;
      line-height: 1.5;
      text-align: justify;
    }
    > .see-more {
      position: absolute;
      bottom: 5rem;
      right: 0;
    }
  }
  &.reverse {
    flex-direction: row-reverse;
    > .content {
      padding: 2rem 10rem 0 0;
    }
  }
  &.cursor-pointer {
    img,
    .title {
      cursor: pointer;
    }
  }
  @media (min-width: 600px) and (max-width: 1200px) {
    > .image {
      img {
        max-width: 40vw;
        height: 35rem;
      }
    }
    > .content {
      padding: 2rem 0 0 5rem;
    }
  }
  @include respond(phone) {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 3rem;
    width: 40%;
    height: 22rem;
    position: static;
    > .image {
      width: 20rem;
      > img {
        display: flex;
        margin-left: auto;
        width: 16rem;
        height: 12rem;
        margin-bottom: 1rem;
      }
    }
    > .content {
      padding: 0;
      > .title {
        color: #0081ab;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: end;
      }
      > .desc {
        position: absolute;
        left: 4rem;
        top: 4rem;
        max-width: calc(55% - 9rem);
        min-width: calc(55% - 9rem);
        font-weight: 400;
      }
      > .see-more {
        position: absolute;
        bottom: 0;
        right: 2rem;
        width: 15rem;
        svg {
          height: 1.5rem;
        }
      }
    }
  }
}
