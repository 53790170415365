.keris-history {
  background-color: #0081ab;
  display: flex;
  padding: 5rem;
  margin-bottom: 5rem;
  font-weight: 300;
  > .container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 1.4rem;
    > img {
      width: 37.6rem;
      height: 56.1rem;
      margin-bottom: 2rem;
    }
    > .desc {
      margin-top: 4rem;
      width: 33.1rem;
      text-align: justify;
    }
  }
}
