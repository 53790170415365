@import '../../sass//mixins';

.header {
  & .museum {
    color: #0081AB;
  }
}

.cendramata {
  padding: 20rem 6rem 10rem;
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-wrap:wrap ;
  min-height: calc(100vh - 216px);
}

.header_cendramata { 
  position: absolute; 
  top: 50px;
  left: 50%;
  transform: translateX(-50%);

  @include respond(tab-land) {
    top: 30px;
  }

  @include respond(phone) {
    width: 80px;
    top: 30px;
  }
}