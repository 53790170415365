.radio-box {
  display: flex;
  column-gap: 1rem;
  .box {
    width: 12rem;
    height: 5.8rem;
    background-color: rgb(217, 217, 217, 0.5);
    display: grid;
    place-items: center;
    border-radius: 0.5rem;
    font-size: 2rem;
    font-weight: 500;
    cursor: pointer;
    &.active {
      background-color: #0081ab;
      color: #fff;
      cursor: default;
    }
  }
}

.select-ticket {
  > .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    > .type {
      width: 18.8rem;
      height: 5.8rem;
      background-color: rgb(217, 217, 217, 0.5);
      display: grid;
      place-items: center;
      border-radius: 0.5rem;
      font-weight: 500;
      font-size: 2rem;
      &.active {
        background-color: #0081ab;
        color: #fff;
      }
    }
    > .price {
      width: 12rem;
      text-align: end;
      font-size: 2rem;
    }
    > .quantity {
      display: flex;
      align-items: center;
      > .operator {
        min-width: 4rem;
        height: 4rem;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 12px;
        display: grid;
        place-items: center;
        font-size: 2.5rem;
        cursor: pointer;
      }
      > span {
        margin: 0 2.5rem;
      }
    }
  }
}
