@import '../../sass//mixins';

.activity {
  .activities {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;
  }
  @include respond(phone) {
    margin-bottom: 5rem;
    .section {
      max-width: 100%;
      .title {
        text-align: center;
      }
      .sub-title {
        display: none;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .activities {
      margin: 1rem 0;
      flex-wrap: wrap;
      gap: 5rem;
      padding: 0 3rem;
    }
  }
}
