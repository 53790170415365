@import '../../sass//mixins';

.ticketing {
  padding-top: 15rem;
  min-height: calc(100vh - 21.6rem);
  > span.kembali {
    margin: 0 0 5rem 0;
    display: flex;
    justify-content: flex-end;
    padding: 0 9.5rem;
    cursor: pointer;
  }
  > .title-column {
    display: flex;
    padding: 0 15rem;
    > .container {
      min-width: 33vw;
    }
    > .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
      > .item {
        text-align: center;
        > img {
          height: 14.8rem;
          width: 15.9rem;
        }
        > figcaption {
          width: 58%;
          margin: 0 auto;
        }
      }
    }
  }
  .title {
    color: #0081ab;
    font-weight: bold;
    font-size: 4rem;
    width: 25.3rem;
  }
  p,
  span {
    font-size: 2rem;
  }
  p:not(:first-child) {
    display: flex;
    margin: 1.5rem 0;
    flex-wrap: wrap;
  }
  span {
    display: flex;
    margin-top: 2.5rem;
  }
  .separator {
    background-color: #000;
    height: 1px;
    margin: 3rem 15rem;
  }
  button,
  a {
    display: flex;
    margin: 5rem auto 5rem;
    background-color: #ff7b47;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    width: 12.3rem;
    height: 5.8rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    &.disable {
      background-color: rgb(217, 217, 217, 0.5);
      cursor: not-allowed;
    }
  }
  .kunjungan {
    width: 100%;
    > h3 {
      margin-bottom: 2rem;
      font-size: 3rem;
      font-weight: 400;
    }
    > .separator {
      margin: 3rem 0 3rem -3rem;
    }
    > input {
      border: none;
      outline: none;
      background-color: rgb(217, 217, 217, 0.5);
      text-align: center;
      height: 5.8rem;
      width: 32.2rem;
      border-radius: 0.5rem;
      align-items: center;
      font-size: 2rem;
      &::placeholder {
        color: rgb(0, 0, 0, 0.35);
      }
    }
  }
  .rangkuman {
    width: 100%;
    > h2 {
      font-size: 3rem;
      font-weight: 400;
    }
    > .content {
      font-size: 2rem;
      > .row {
        display: flex;
        margin: 3rem 0;
        > .column-name {
          min-width: 20rem;
        }
        > .column-desc {
          font-weight: 500;
          &.jumlah-tiket {
            display: flex;
            column-gap: 0.5rem;
            width: 100%;
            span {
              margin-top: 0;
            }
            > .column {
              margin: 0;
              > .row {
                display: flex;
                column-gap: 2rem;
                > span.none {
                  display: none;
                }
                > span:nth-child(2) {
                  width: 7rem;
                }
                > span:nth-child(3) {
                  width: 10rem;
                  display: flex;
                  justify-content: flex-end;
                }
              }
            }
          }
        }
      }
    }
    > .separator {
      margin: 3rem 0 3rem -3rem;
    }
  }
  @include respond(tab-land) {
    .title {
      font-size: 3rem;
    }
    .title-column {
      padding: 0 10rem;
      > .grid > .item > img {
        height: 9rem;
        width: 9rem;
      }
    }
    .separator {
      margin: 3rem 10rem;
    }
    .radio-box {
      flex-wrap: wrap;
      gap: 1rem;
    }
    .select-ticket > .content > {
      .type {
        width: 10rem;
        height: 5.8rem;
        font-size: 2rem;
      }
      .quantity > span {
        margin: 0 1rem;
      }
      .price {
        margin-right: 2rem;
      }
    }
  }
  @include respond(phone) {
    padding-top: 10rem;
    > span.kembali {
      margin: 1rem 0 2rem 0;
      display: flex;
      justify-content: flex-end;
      padding: 0 4.2rem;
      cursor: pointer;
    }
    > .title-column {
      padding: 0 3rem;
      > .grid {
        > .item {
          img {
            height: 6rem;
            width: 6rem;
          }
          figcaption {
            width: 6rem;
            font-size: 1.2rem;
          }
        }
      }
      &.page-3 {
        flex-direction: column;
        .container {
          width: 100%;
          .title {
            max-width: 100%;
            text-align: center;
            margin: 0 auto;
            font-size: 2rem;
          }
        }
      }
    }
    p,
    span {
      font-size: 1.7rem;
    }
    .title {
      font-size: 2rem;
      max-width: 20vw;
      line-height: 1.5;
    }
    .separator {
      margin: 3rem;
    }
    .kunjungan {
      width: 65%;
      h3 {
        font-size: 2rem;
      }
      .radio-box {
        flex-wrap: wrap;
        gap: 1rem;
        .box {
          width: 7rem;
          height: 3rem;
          font-size: 1.5rem;
        }
      }
      .select-ticket {
        > .content > {
          .type {
            width: 7rem;
            height: 3rem;
            font-size: 1.5rem;
          }
          .price {
            width: 8rem;
            font-size: 1.5rem;
            text-align: start;
            margin-right: 0;
          }
          .quantity > {
            .operator {
              font-size: 1.5rem;
              min-width: 2rem;
              height: 2rem;
              border-radius: 0.5rem;
            }
            span {
              margin: 0 1rem;
              font-size: 1.5rem;
            }
          }
        }
      }
      > input {
        width: 20rem;
        font-size: 1.5rem;
      }
    }
    .rangkuman {
      h2 {
        font-size: 1.7rem;
        text-align: center;
        margin: 3rem auto 5rem;
        width: 67%;
      }
      .content {
        font-size: 1.7rem;
        > .row > {
          .column-name {
            min-width: 15rem;
          }
          .column-desc {
            &.jumlah-tiket {
              .column .row {
                column-gap: 1rem;
                span:nth-child(2) {
                  width: 5rem;
                }
              }
            }
          }
        }
      }
      .separator {
        margin: 3rem 0 3rem 0rem;
      }
    }
  }
}
