@import '../../sass//mixins';

.about {
  margin: 5rem 0;
  > .row-box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
    > .box {
      width: 32.5rem;
      height: 14.2rem;
      margin-top: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      background-color: #f9f9f9;
      border-radius: 0.5rem;
      cursor: pointer;
      color: #000;
      transition: all 0.3s;
      svg path {
        fill: rgb(0, 129, 171);
      }
      &:hover {
        background-color: #0081ab;
        color: #ffffff;
        svg path {
          fill: #fff;
        }
      }
      > h5 {
        margin-top: 0.5rem;
      }
    }
  }
  @include respond(phone) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 3rem;
    margin-top: 1rem;
    > .row-box {
      gap: 2rem;
      flex-direction: column-reverse;
      > .box {
        width: 16rem;
        height: 7rem;
        margin-top: 0rem;
        &:first-child {
          margin-top: 0;
        }
        svg {
          height: 2.5rem;
          width: 3rem;
        }
      }
    }
  }
}
