@import '../../sass/mixins';

.activity-card {
  width: 23.2rem;
  box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content {
    height: 100%;
    background-color: #dfdfdf;
    padding: 3rem 2rem 1rem 2rem;
    .title {
      font-weight: bold;
      margin-bottom: 1rem;
      line-height: 1.5;
    }
    .desc {
      line-height: 1.4\3;
    }
  }
  img {
    width: 100%;
    height: 13.8rem;
    object-fit: cover;
    box-shadow: 0 8px 8px -2px rgba(0, 0, 0, 0.7);
  }
  @include respond(phone) {
    width: calc(0.7 * 23.2rem);
    height: calc(0.7 * 32.1rem);
    .content {
      height: calc(0.7 * 18.3rem);
      background-color: #dfdfdf;
      padding: 1rem 1rem 1rem 2rem;
      .title {
        font-weight: bold;
        line-height: 1.5;
        font-size: 1.2rem;
        margin-bottom: 0rem;
      }
      .desc {
        line-height: 1.4;
        font-size: 1.2rem;
      }
    }
    img {
      width: 100%;
      height: calc(0.7 * 18.3rem);
      object-fit: cover;
    }
  }
}
