@import '../../sass//mixins';

.popup-item {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(71, 68, 68, 0.768);
  bottom: 0;
  z-index: 999999;
  > .container {
    position: fixed;
    z-index: 10000;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2rem;;
    > svg {
      position: absolute;
      cursor: pointer;
      z-index: 100000;
      &:nth-child(1) {
        right: 4rem;
        top: 2rem;
      }
      &:nth-child(2) {
        right: 6rem;
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        left: 6rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    > .scroll {
      display: flex;
      height: 90vh;
      width: 90vw;
      overflow-y: auto;
      padding: 10rem 15rem 20rem;
      justify-content: space-between;
      gap: 10%;
      > .content {
        > .title {
          font-weight: bold;
          font-size: 2rem;
          padding-right: 45px;
        }
        > .short-desc {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        > .separator {
          height: 1px;
          background-color: #0081ab;
          width: 93%;
          margin: 2.5rem 0;
          box-shadow: grey 0 3px 3px;
        }
        > .sub-title {
          margin: 2rem 0 0;
        }
        > .text {
          font-size: 1.4rem;
          width: 93%;
          text-align: justify;
          &.pb {
            padding-bottom: 7rem;
          }
        }
      }
      > .image {
        display: flex;
        flex-direction: column;
        .img-container {
          min-width: 459px;
          min-height: 459px;
          height: 459px;
          background-color: #fff;
          border-radius: 50px;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          overflow: hidden;
          text-align: center;
          > img {
            width: auto;
            height: 100%;
            max-height: 459px;
            max-width: 459px;
            object-fit: cover;
          }
        }
        > .image-thumb {
          gap: 2rem;
          margin-top: 3rem;
          max-width: 459px;
          position: relative;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          img {
            width: 97px;
            height: 97px;
            border-radius: 25px;
            cursor: pointer;
            object-fit: cover;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          }
        }
      }
    }
    @include respond(tab-land) {
      > .scroll {
        padding: 10rem 10rem 20rem;
        > .image {
          .img-container {
            min-width: 300px;
            min-height: 300px;
            height: 300px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 30px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            > img {
              width: auto;
              height: 100%;
              max-height: 300px;
              max-width: 300px;
              object-fit: cover;
            }
          }
          > .image-thumb {
            display: flex;
            align-items: flex-start;
            gap: 1.5rem;
            margin-top: 3rem;
            img {
              width: 70px;
              height: 70px;
              border-radius: 15px;
              cursor: pointer;
              box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            }
          }
        } 
      }
    }
    @include respond(tab-port) {
      > .scroll {
        padding: 10rem 7rem 20rem;
        > .image {
          .img-container {
            width: 225px;
            height: 225px;
            min-width: 225px;
            min-height: 225px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 30px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            > img {
              width: auto;
              height: 100%;
              max-height: 225px;
              max-width: 225px;
              object-fit: cover;
            }
          }
          > .image-thumb {
            gap: 1.5rem;
            margin-top: 3rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            img {
              width: 50px;
              height: 50px;
              border-radius: 15px;
              cursor: pointer;
              box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
              object-fit: cover;
            }
          }

        } 
      }
    }
  }
}

.popup-item.mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(71, 68, 68, 0.768);
  bottom: 0;
  z-index: 999999;
  > .container {
    position: fixed;
    z-index: 10000;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2rem;
    width: 90%;
    overflow-y: auto;
      height: 90%;
    > svg {
      right: 2rem;
      top: 2rem;
      height: 3rem;
      width: 3rem;
      position: absolute;
      cursor: pointer;
    }
    > .scroll {
      display: flex;
      justify-content: center;
      padding: 30px;
      gap: 10%;
      > .content {
        > .title {
          font-weight: bold;
          font-size: 2rem;
          padding-right: 45px;
        }
        > .short-desc {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        > .separator {
          height: 1px;
          background-color: #0081ab;
          margin: 2.5rem 0;
          box-shadow: grey 0 3px 3px;
        }
        > .sub-title {
          margin: 2rem 0 0;
        }
        > .text {
          font-size: 1.4rem;
          text-align: justify;
          &.pb {
            padding-bottom: 7rem;
          }
        }
        > .image {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 459px;
          .img-container {
            margin-top: 1rem;
            width: 250px;
            height: 250px;
            min-width: 250px;
            min-height: 250px;
            overflow: hidden;
            background-color: #fff;
            border-radius: 50px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            text-align: center;
            > img {
              width: auto;
              height: 100%;
              max-height: 250px;
              max-width: 250px;
              object-fit: cover;
            }
          }
          > .image-thumb {
            width: 100%;
            gap: 1rem;
            margin-top: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 50px;
              height: 50px;
              border-radius: 10px;
              cursor: pointer;
              box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            }
          }
        }
      }
      }
  }
}

.dot {
  background-color: rgb(166, 160, 160);
  width: 8px;
  height: 8px;
  border-radius: 8px;
  cursor: pointer;
  &.active {
    background-color: black;
  }
}

.icon {
  position: absolute;
  background-color: rgb(166, 160, 160);
  top: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transform: translateY(-50%);
  opacity: 50%;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 90%;
  }
  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }
}