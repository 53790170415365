@import '../../sass/mixins';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 10rem;
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  transition: box-shadow 0.3s;
  @include respond(tab-port) {
    padding: 1.5rem 4rem;
  }
  &.shadow {
    box-shadow: 0 2px 10px black;
  }
  img {
    width: 12.1rem;
    height: 7.8rem;
    cursor: pointer;
    @include respond(tab-port) {
      width: calc(0.8 * 12.1rem);
      height: calc(0.8 * 7.8rem);
    }
  }
  ul {
    display: flex;
    column-gap: 5rem;
    font-size: 2rem;

    > .menu {
      color: #000;
      font-size: 4rem;
      cursor: pointer;
    }
  }
  > .back {
    font-size: 2rem;
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
    }
  }
}
li {
  cursor: pointer;
  font-weight: 500;
  transition: all 0.5s;
  &.active {
    color: var(--color-primary);
  }
}
.menu-mobile {
  position: fixed;
  top: 9.5rem;
  right: 8rem;
  z-index: 1001;
  display: flex;
  line-height: 6rem;
  flex-direction: column;
  width: 25rem;
  height: 40rem;
  background-color: #d9d9d9;
  padding: 2rem 5rem 2rem 2rem;
  text-align: end;
  font-size: 2.5rem;
  border-radius: 10px;
  font-weight: 500;
  > .menu {
    color: #000;
    font-size: 4rem;
    cursor: pointer;
    &.close {
      font-size: 3rem;
    }
  }
}
